import { APIStatus } from '@application/common/enums/api-status';
import { createSlice } from '@reduxjs/toolkit';
const initialState = {
    status: APIStatus.IDLE,
    isToggle: false
};
export const toggleSlice = createSlice({
    name: 'toggle',
    initialState,
    reducers: {
        toggleMenu(state) {
            state.isToggle = !state.isToggle;
        }
    },
    extraReducers: (builder) => {
    }
});
export const { toggleMenu } = toggleSlice.actions;
export default toggleSlice.reducer;
