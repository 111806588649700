import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Navigate, Route, Routes } from "react-router-dom";
import ProtectedRoute from "./ProtectedRoutes";
import AuthLayout from "./AuthLayout";
import KidsLayout from "./KidsLayout";
import TeacherLogin from "../auth/teacher-login";
import StudentLogin from "../auth/student-login";
import CoordinatorLogin from "../auth/coordinator-login";
import Teacher from "../teacher";
import Kid from "../kid";
import FaqNewsLayout from "./FaqNewsLayout";
import Faqs from "../teacher/faqs";
import News from "../teacher/news";
import AnnualPlan from "../teacher/annual-plan";
import LocalStorageService from "@infrastructure/local-storage-service";
import ForgetPassword from "../auth/forget-password";
import ResetPassword from "../auth/reset-password";
import TeacherMain from "../teacher/main";
import KidsMain from "../kid/main";
const AppRoutes = () => {
    const loggedInRole = LocalStorageService.getLoggedInRole();
    return (_jsxs(Routes, { children: [_jsxs(Route, { element: _jsx(AuthLayout, {}), children: [_jsx(Route, { path: "teacher-login", element: _jsx(TeacherLogin, {}) }), _jsx(Route, { path: "student-login", element: _jsx(StudentLogin, {}) }), _jsx(Route, { path: "coordinator-login", element: _jsx(CoordinatorLogin, {}) }), _jsx(Route, { path: "forget-password", element: _jsx(ForgetPassword, {}) }), _jsx(Route, { path: "reset-password", element: _jsx(ResetPassword, {}) })] }), _jsxs(Route, { element: _jsx(KidsLayout, {}), children: [_jsx(Route, { element: _jsx(ProtectedRoute, { requiredRoles: ["Teacher", "Supervisor"] }), children: _jsxs(Route, { path: "teacher", children: [_jsx(Route, { path: "", element: _jsx(TeacherMain, {}) }), _jsx(Route, { path: "courses/:seasonId/:themeId", element: _jsx(Teacher, {}) })] }) }), _jsx(Route, { element: _jsx(ProtectedRoute, { requiredRoles: ["Student"] }), children: _jsxs(Route, { path: "student", children: [_jsx(Route, { path: "", element: _jsx(KidsMain, {}) }), _jsx(Route, { path: "courses/:seasonId/:themeId", element: _jsx(Kid, {}) })] }) })] }), _jsx(Route, { element: _jsx(FaqNewsLayout, {}), children: _jsx(Route, { element: _jsx(ProtectedRoute, { requiredRoles: ["Teacher", "Supervisor"] }), children: _jsxs(Route, { path: "teacher", children: [_jsx(Route, { path: "annual-plan", element: _jsx(AnnualPlan, {}) }), _jsx(Route, { path: "faqs", element: _jsx(Faqs, {}) }), _jsx(Route, { path: "news", element: _jsx(News, {}) })] }) }) }), _jsx(Route, { path: "*", element: _jsx(Navigate, { to: `${loggedInRole === "Teacher" || loggedInRole === "Supervisor"
                        ? "/teacher"
                        : "/student"}`, replace: true }) })] }));
};
export default AppRoutes;
