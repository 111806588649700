import { translate } from "../helpers/translate";
export var CourseInstructionCategory;
(function (CourseInstructionCategory) {
    CourseInstructionCategory[CourseInstructionCategory["Instruction"] = 0] = "Instruction";
    CourseInstructionCategory[CourseInstructionCategory["ReadingSheet"] = 1] = "ReadingSheet";
    CourseInstructionCategory[CourseInstructionCategory["WorkSheet"] = 2] = "WorkSheet";
    CourseInstructionCategory[CourseInstructionCategory["Craft"] = 3] = "Craft";
    CourseInstructionCategory[CourseInstructionCategory["Language"] = 4] = "Language";
    CourseInstructionCategory[CourseInstructionCategory["Calculate"] = 5] = "Calculate";
    CourseInstructionCategory[CourseInstructionCategory["Video"] = 6] = "Video";
    CourseInstructionCategory[CourseInstructionCategory["Audio"] = 7] = "Audio";
    CourseInstructionCategory[CourseInstructionCategory["Picture"] = 8] = "Picture";
    CourseInstructionCategory[CourseInstructionCategory["Miscellaneous"] = 9] = "Miscellaneous";
})(CourseInstructionCategory || (CourseInstructionCategory = {}));
export const CourseInstructionCategoryStatusMap = new Map([
    [
        CourseInstructionCategory.Instruction,
        translate("enums.courseInstructionCategory.instruction"),
    ],
    [
        CourseInstructionCategory.ReadingSheet,
        translate("enums.courseInstructionCategory.readingSheet"),
    ],
    [
        CourseInstructionCategory.WorkSheet,
        translate("enums.courseInstructionCategory.workSheet"),
    ],
    [
        CourseInstructionCategory.Craft,
        translate("enums.courseInstructionCategory.craft"),
    ],
    [
        CourseInstructionCategory.Language,
        translate("enums.courseInstructionCategory.language"),
    ],
    [
        CourseInstructionCategory.Calculate,
        translate("enums.courseInstructionCategory.calculate"),
    ],
    [
        CourseInstructionCategory.Video,
        translate("enums.courseInstructionCategory.video"),
    ],
    [
        CourseInstructionCategory.Audio,
        translate("enums.courseInstructionCategory.audio"),
    ],
    [
        CourseInstructionCategory.Picture,
        translate("enums.courseInstructionCategory.picture"),
    ],
    [
        CourseInstructionCategory.Miscellaneous,
        translate("enums.courseInstructionCategory.miscellaneous"),
    ]
]);
