import { jsx as _jsx } from "react/jsx-runtime";
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { store } from './application/store';
import App from './App';
import './index.css';
import { BrowserRouter } from 'react-router-dom';
import './i18n';
import i18n from './i18n';
import { I18nextProvider } from 'react-i18next';
const container = document.getElementById('root');
const root = createRoot(container);
root.render(_jsx(I18nextProvider, { i18n: i18n, children: _jsx(Provider, { store: store, children: _jsx(BrowserRouter, { children: _jsx(App, {}) }) }) }));
