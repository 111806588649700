import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import "./style.scss";
import { useAppDispatch, useAppSelector } from "@application/store/useStore";
import logo from "../../../../logo.svg";
import { useEffect, useState } from "react";
import { Link, NavLink, useParams, } from "react-router-dom";
import { getSeasonListAsync } from "@application/features/season/seasonThunk";
import { getTeacherSettingsAsync } from "@application/features/teacher/teacherThunk";
import loginImage from "../../../../../public/images/tomatoe.png";
import { Divider, IconButton, Typography, useTheme, } from "@mui/material";
import { ChevronLeft, ChevronRight } from "@mui/icons-material";
import { logout } from "@application/features/auth/authSlice";
import { setActiveSeason } from "@application/features/season/seasonSlice";
import ChangePasswordDialog from "../changePassword/changePasswordDialog";
const Header = () => {
    const storageUrl = process.env.STORAGE_ACCOUNT;
    const [featureImage, setFeatureImage] = useState(undefined);
    const { seasonList } = useAppSelector((state) => state.season);
    const { teacherSetting } = useAppSelector((state) => state.teacher);
    const { seasonId, themeId } = useParams();
    const { featuredImage } = useAppSelector((state) => state.theme);
    const dispatch = useAppDispatch();
    const [changePasswordOpen, setChangePasswordOpen] = useState(false); // State to manage dialog open/close
    useEffect(() => {
        const teacherId = currentUser?.id || "";
        dispatch(getSeasonListAsync(teacherId));
        dispatch(getTeacherSettingsAsync());
    }, [dispatch]);
    useEffect(() => {
        if (storageUrl && featuredImage) {
            setFeatureImage(storageUrl.replace("fileShoulebeReplace", featuredImage));
        }
    }, [storageUrl, featuredImage]);
    const [isCollapsed, setIsCollapsed] = useState(true);
    const handleChangePasswordOpen = () => {
        setChangePasswordOpen(true); // Function to open the dialog
    };
    const handleChangePasswordClose = () => {
        setChangePasswordOpen(false); // Function to close the dialog
    };
    const handleButtonClick = () => {
        setIsCollapsed(!isCollapsed);
    };
    useEffect(() => {
        if (seasonId && themeId && seasonList.length > 0) {
            seasonList.map((item, index) => {
                if (item.id == seasonId) {
                    dispatch(setActiveSeason(onSetActiveSeason(item.name.toLocaleLowerCase())));
                }
            });
        }
    }, [dispatch, seasonId, themeId, seasonList, seasonList.length > 0]);
    const onSetActiveSeason = (activeSeasonName) => {
        if (activeSeasonName === "autumn")
            return "primary";
        else if (activeSeasonName === "winter")
            return "winter";
        else if (activeSeasonName === "summer")
            return "summer";
        else if (activeSeasonName === "spring")
            return "spring";
        return "primary";
    };
    const { activeSeason } = useAppSelector((state) => state.season);
    const { currentUser } = useAppSelector((state) => state.auth);
    const getSeasonColor = (activeSeason) => {
        if (activeSeason === "summer") {
            return "#70b250";
        }
        else if (activeSeason === "winter") {
            return "#2d8ac5";
        }
        else if (activeSeason === "primary") {
            return "#f68735";
        }
        else if (activeSeason === "spring") {
            return "#fdc854";
        }
        else {
            return "#70b250";
        }
    };
    const getSeasonName = (name) => {
        if (name.toLocaleLowerCase() === "summer")
            return "zomer";
        else if (name.toLocaleLowerCase() === "winter")
            return "winter";
        else if (name.toLocaleLowerCase() === "autumn")
            return "herfst";
        else if (name.toLocaleLowerCase() === "spring")
            return "lente";
        else
            return "";
    };
    const theme = useTheme();
    return (_jsxs(_Fragment, { children: [_jsx("img", { src: logo, className: "kids-logo", height: "10%", alt: "" }), featureImage && (_jsx("img", { src: featureImage, className: "feature-image", height: "40%", alt: "Theme" })), _jsxs(NavLink, { className: "login-btn", onClick: () => {
                    dispatch(logout());
                }, to: "", children: [_jsx("img", { src: loginImage, alt: "logout", height: 75 }), _jsx(Typography, { sx: {
                            fontSize: "18px",
                            textDecoration: "none",
                            color: theme.palette.success.light,
                            fontFamily: "'Marykate'",
                            position: "absolute",
                            top: "50%",
                            left: "50%",
                            transform: "translate(-50%, -50%)",
                        }, children: "uitloggen" })] }), _jsx("div", { className: "top-link", children: seasonList &&
                    seasonList.map((item, index) => {
                        return (_jsx(NavLink, { className: item.name.toLocaleLowerCase(), onClick: () => {
                                setIsCollapsed(true);
                                dispatch(setActiveSeason(onSetActiveSeason(item.name.toLocaleLowerCase())));
                            }, to: `${currentUser?.role === "Student" ? "student" : "teacher"}/courses/${item.id}/${item.themeId}`, title: item.name, children: getSeasonName(item.name) }, index));
                    }) }), currentUser && currentUser.role !== "Student" && (_jsxs("div", { className: `more-container ${isCollapsed ? "collapsed" : ""}`, children: [_jsx(IconButton, { sx: {
                            color: "#fff",
                            backgroundColor: getSeasonColor(activeSeason),
                            borderTopRightRadius: "0",
                            borderBottomRightRadius: "0",
                            position: "absolute",
                            left: "0",
                            bottom: "20px",
                            transform: "translateX(-100%)",
                            "&:hover": {
                                backgroundColor: getSeasonColor(activeSeason),
                                color: "#fff",
                            },
                        }, onClick: handleButtonClick, children: isCollapsed ? _jsx(ChevronLeft, {}) : _jsx(ChevronRight, {}) }), _jsxs("div", { className: "more-links", children: [_jsx("div", { className: "header", children: _jsx("h6", { children: "Extra koppelingen" }) }), _jsx("div", { className: "body", children: _jsxs("ul", { children: [_jsx("li", { children: _jsx(Link, { to: `/teacher/annual-plan`, children: "Jaarplan" }) }), _jsx("li", { children: _jsx(Link, { to: `/teacher/news`, children: "Academie" }) }), _jsx("li", { children: _jsx(Link, { to: `/teacher/faqs`, children: "Veelgestelde vragen" }) }), _jsx("li", { children: _jsx(Divider, {}) }), _jsx("li", { children: _jsx("a", { href: "https://www.wijzneus.nl/", target: "_blank", rel: "noreferrer", children: "Website" }) }), _jsx("li", { style: { cursor: "pointer" }, onClick: handleChangePasswordOpen, children: _jsx("span", { className: "heading-span", children: "Wachtwoord Wijzigen" }) }), _jsxs("li", { children: [_jsxs("span", { style: { verticalAlign: "text-bottom" }, children: ["Klassencode:", " "] }), _jsx("span", { style: {
                                                        fontSize: 24,
                                                        color: "#414850",
                                                        fontWeight: "normal",
                                                        letterSpacing: "0.15cm",
                                                    }, children: currentUser.voucherCode })] })] }) })] }), _jsx(ChangePasswordDialog, { open: changePasswordOpen, onClose: handleChangePasswordClose })] }))] }));
};
export default Header;
