import { getErrorPayload } from "../../common/helpers/api-helper";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { addSuccessNotification } from "../common/notificationSlice";
import ThemeApi from "@infrastructure/theme-api";
export const getThemesAsync = createAsyncThunk("theme/getThemes", async (_, { rejectWithValue, fulfillWithValue }) => {
    try {
        const response = await ThemeApi.getThemes();
        return fulfillWithValue(response.data);
    }
    catch (error) {
        return rejectWithValue(getErrorPayload(error));
    }
});
export const getThemeByIdAsync = createAsyncThunk("theme/getTheme", async (credentials, { rejectWithValue, fulfillWithValue }) => {
    try {
        const response = await ThemeApi.getTheme(credentials);
        return fulfillWithValue(response.data);
    }
    catch (error) {
        return rejectWithValue(getErrorPayload(error));
    }
});
export const getThemeBySeasonandTeacherAsync = createAsyncThunk("theme/getThemeBySeasonId", async (credentials, { rejectWithValue, fulfillWithValue }) => {
    try {
        const response = await ThemeApi.getThemesBySeasonandTeacher(credentials);
        return fulfillWithValue(response.data);
    }
    catch (error) {
        return rejectWithValue(getErrorPayload(error));
    }
});
export const addThemeAsync = createAsyncThunk("theme/addTheme", async (credentials, { rejectWithValue, fulfillWithValue, dispatch }) => {
    try {
        const response = await ThemeApi.addTheme(credentials);
        if (response.status === 200) {
            dispatch(addSuccessNotification("Thema succesvol toegevoegd!"));
            return fulfillWithValue(true);
        }
        else {
            return fulfillWithValue(false);
        }
    }
    catch (error) {
        return rejectWithValue(getErrorPayload(error));
    }
});
export const updateThemeAsync = createAsyncThunk("theme/updateTheme", async (credentials, { rejectWithValue, fulfillWithValue, dispatch }) => {
    try {
        const response = await ThemeApi.updateTheme(credentials);
        if (response.status === 200) {
            dispatch(addSuccessNotification("Thema succesvol bijgewerkt!"));
            return fulfillWithValue(true);
        }
        else {
            return fulfillWithValue(false);
        }
    }
    catch (error) {
        return rejectWithValue(getErrorPayload(error));
    }
});
export const enableDisableThemeAsync = createAsyncThunk("theme/enableDisableTheme", async (credentials, { rejectWithValue, fulfillWithValue, dispatch }) => {
    try {
        const response = await ThemeApi.enableDisableTheme(credentials);
        if (response.status === 200) {
            dispatch(addSuccessNotification("Thema succesvol bijgewerkt!"));
            return fulfillWithValue(true);
        }
        else {
            return fulfillWithValue(false);
        }
    }
    catch (error) {
        return rejectWithValue(getErrorPayload(error));
    }
});
export const enableDisableTeacherThemeAsync = createAsyncThunk("teacher-theme/enableDisableTheme", async (credentials, { rejectWithValue, fulfillWithValue, dispatch }) => {
    try {
        const response = await ThemeApi.enableDisableTeacherTheme(credentials);
        if (response.status === 200) {
            dispatch(addSuccessNotification("Thema succesvol bijgewerkt!"));
            return fulfillWithValue(true);
        }
        else {
            return fulfillWithValue(false);
        }
    }
    catch (error) {
        return rejectWithValue(getErrorPayload(error));
    }
});
