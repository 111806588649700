export var RoleEnum;
(function (RoleEnum) {
    RoleEnum[RoleEnum["SuperAdmin"] = 0] = "SuperAdmin";
    RoleEnum[RoleEnum["Admin"] = 1] = "Admin";
    RoleEnum[RoleEnum["Board"] = 2] = "Board";
    RoleEnum[RoleEnum["School"] = 3] = "School";
    RoleEnum[RoleEnum["Teacher"] = 4] = "Teacher";
    RoleEnum[RoleEnum["Supervisor"] = 5] = "Supervisor";
    RoleEnum[RoleEnum["Student"] = 6] = "Student";
})(RoleEnum || (RoleEnum = {}));
