import { APIStatus } from '@application/common/enums/api-status';
import { createSlice } from '@reduxjs/toolkit';
import { enableDisableSchoolAsync, getSchoolByIdAsync, getSchoolDropdownsAsync, getSchoolsAsync } from './schoolThunk';
const initialState = {
    status: APIStatus.IDLE,
    schoolDropdownList: []
};
export const schoolSlice = createSlice({
    name: 'school',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getSchoolsAsync.pending, (state) => {
            state.status = APIStatus.PENDING;
            state.apiError = undefined;
        })
            .addCase(getSchoolsAsync.fulfilled, (state, action) => {
            state.status = APIStatus.IDLE;
            state.schools = action.payload;
        })
            .addCase(getSchoolsAsync.rejected, (state, action) => {
            state.status = APIStatus.FAILED;
            state.apiError = action.payload;
        });
        builder
            .addCase(getSchoolDropdownsAsync.pending, (state) => {
            state.status = APIStatus.PENDING;
            state.apiError = undefined;
        })
            .addCase(getSchoolDropdownsAsync.fulfilled, (state, action) => {
            state.status = APIStatus.IDLE;
            state.schoolDropdownList = action.payload;
        })
            .addCase(getSchoolDropdownsAsync.rejected, (state, action) => {
            state.status = APIStatus.FAILED;
            state.apiError = action.payload;
        });
        builder
            .addCase(getSchoolByIdAsync.pending, (state) => {
            state.status = APIStatus.PENDING;
            state.apiError = undefined;
        })
            .addCase(getSchoolByIdAsync.fulfilled, (state, action) => {
            state.status = APIStatus.IDLE;
            state.school = action.payload;
        })
            .addCase(getSchoolByIdAsync.rejected, (state, action) => {
            state.status = APIStatus.FAILED;
            state.apiError = action.payload;
        });
        // builder
        //     .addCase(addBoardsAsync.pending, (state) => {
        //         state.status = APIStatus.PENDING;
        //         state.apiError = undefined;
        //     })
        //     .addCase(addBoardsAsync.fulfilled, (state, action) => {
        //         state.status = APIStatus.IDLE;
        //     })
        //     .addCase(addBoardsAsync.rejected, (state, action) => {
        //         state.status = APIStatus.FAILED;
        //         state.apiError = action.payload;
        //     });
        // builder
        //     .addCase(updateBoardsAsync.pending, (state) => {
        //         state.status = APIStatus.PENDING;
        //         state.apiError = undefined;
        //     })
        //     .addCase(updateBoardsAsync.fulfilled, (state, action) => {
        //         state.status = APIStatus.IDLE;
        //     })
        //     .addCase(updateBoardsAsync.rejected, (state, action) => {
        //         state.status = APIStatus.FAILED;
        //         state.apiError = action.payload;
        //     });
        builder
            .addCase(enableDisableSchoolAsync.pending, (state) => {
            state.status = APIStatus.PENDING;
            state.apiError = undefined;
        })
            .addCase(enableDisableSchoolAsync.fulfilled, (state, action) => {
            state.status = APIStatus.IDLE;
        })
            .addCase(enableDisableSchoolAsync.rejected, (state, action) => {
            state.status = APIStatus.FAILED;
            state.apiError = action.payload;
        });
    }
});
export const {} = schoolSlice.actions;
export default schoolSlice.reducer;
