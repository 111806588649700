import { v4 as uuid } from 'uuid';
const BROWSER_SESSION_ID = 'BROWSER_SESSION_ID';
const getBrowserId = () => {
    const browserId = localStorage.getItem(BROWSER_SESSION_ID);
    if (browserId === null || browserId === undefined || browserId === '') {
        const guid = uuid();
        localStorage.setItem(BROWSER_SESSION_ID, guid);
        return guid;
    }
    else {
        return browserId;
    }
};
const SessionStorageService = {
    getBrowserId,
};
export default SessionStorageService;
