import { APIStatus } from "@application/common/enums/api-status";
import { createSlice } from "@reduxjs/toolkit";
import { getCourseItemsAsync, getCoursesAsync, getDownloadAndViewCourseAsync, getThemeCoursesAsync, } from "./courseThunk";
const initialState = {
    status: APIStatus.IDLE,
    courses: [],
    featureImage: "",
    courseItem: {},
    themeCourses: [],
};
export const courseSlice = createSlice({
    name: "course",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getCoursesAsync.pending, (state) => {
            state.status = APIStatus.PENDING;
            state.apiError = undefined;
        })
            .addCase(getCoursesAsync.fulfilled, (state, action) => {
            state.status = APIStatus.IDLE;
            state.courses = action.payload;
        })
            .addCase(getCoursesAsync.rejected, (state, action) => {
            state.status = APIStatus.FAILED;
            state.apiError = action.payload;
        });
        builder
            .addCase(getDownloadAndViewCourseAsync.pending, (state) => {
            state.status = APIStatus.PENDING;
            state.apiError = undefined;
        })
            .addCase(getDownloadAndViewCourseAsync.fulfilled, (state, action) => {
            state.status = APIStatus.IDLE;
            state.featureImage = action.payload;
        })
            .addCase(getDownloadAndViewCourseAsync.rejected, (state, action) => {
            state.status = APIStatus.FAILED;
            state.apiError = action.payload;
        });
        builder
            .addCase(getCourseItemsAsync.pending, (state) => {
            state.status = APIStatus.PENDING;
            state.apiError = undefined;
        })
            .addCase(getCourseItemsAsync.fulfilled, (state, action) => {
            state.status = APIStatus.IDLE;
            state.courseItem = action.payload;
        })
            .addCase(getCourseItemsAsync.rejected, (state, action) => {
            state.status = APIStatus.FAILED;
            state.apiError = action.payload;
        });
        builder
            .addCase(getThemeCoursesAsync.pending, (state) => {
            state.status = APIStatus.PENDING;
            state.apiError = undefined;
        })
            .addCase(getThemeCoursesAsync.fulfilled, (state, action) => {
            state.status = APIStatus.IDLE;
            state.themeCourses = action.payload;
        })
            .addCase(getThemeCoursesAsync.rejected, (state, action) => {
            state.status = APIStatus.FAILED;
            state.apiError = action.payload;
        });
        // builder
        //     .addCase(enableDisableCourseAsync.pending, (state) => {
        //         state.status = APIStatus.PENDING;
        //         state.apiError = undefined;
        //     })
        //     .addCase(enableDisableCourseAsync.fulfilled, (state, action) => {
        //         state.status = APIStatus.IDLE;
        //         state.featureImage = action.payload;
        //     })
        //     .addCase(enableDisableCourseAsync.rejected, (state, action) => {
        //         state.status = APIStatus.FAILED;
        //         state.apiError = action.payload;
        //     });
    },
});
export const {} = courseSlice.actions;
export default courseSlice.reducer;
