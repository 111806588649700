import { createAsyncThunk } from "@reduxjs/toolkit";
import AnnualPlanApi from "@infrastructure/annual-plan-api";
import { getErrorPayload } from "@application/common/helpers/api-helper";
export const getAnnualPlanAsync = createAsyncThunk("annualPlan/getAnnualPlan", async (credentials, { rejectWithValue, fulfillWithValue }) => {
    try {
        const response = await AnnualPlanApi.getAnnualPlan(credentials);
        return fulfillWithValue(response.data);
    }
    catch (error) {
        return rejectWithValue(getErrorPayload(error));
    }
});
