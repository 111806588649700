import { APIStatus } from '@application/common/enums/api-status';
import { createSlice } from '@reduxjs/toolkit';
import { getKidCoursesAsync } from './kidCourseThunk';
const initialState = {
    status: APIStatus.IDLE,
    kidCourses: [],
    kidsIframeModelOpen: false,
    isIframeLoading: false
};
export const kidCourseSlice = createSlice({
    name: 'kidCourse',
    initialState,
    reducers: {
        toggleKidsIframeModelOpen(state) {
            state.kidsIframeModelOpen = !state.kidsIframeModelOpen;
        },
        setIframeLoading(state, action) {
            state.isIframeLoading = action.payload;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(getKidCoursesAsync.pending, (state) => {
            state.status = APIStatus.PENDING;
            state.apiError = undefined;
        })
            .addCase(getKidCoursesAsync.fulfilled, (state, action) => {
            state.status = APIStatus.IDLE;
            state.kidCourses = action.payload;
        })
            .addCase(getKidCoursesAsync.rejected, (state, action) => {
            state.status = APIStatus.FAILED;
            state.apiError = action.payload;
        });
    }
});
export const { toggleKidsIframeModelOpen, setIframeLoading } = kidCourseSlice.actions;
export default kidCourseSlice.reducer;
