import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useEffect } from "react";
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Grid, OutlinedInput, InputAdornment, IconButton, FormControl, InputLabel, } from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import WnValidation from "@ui/components/wn-validation";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { updateTeacherPasswordAsync } from "@application/features/teacher/teacherThunk";
import { useAppDispatch } from "@application/store/useStore";
const ChangePasswordDialog = ({ open, onClose, }) => {
    const { reset, watch, control, formState: { errors }, handleSubmit, } = useForm({
        defaultValues: {
            currentPassword: "",
            newPassword: "",
            confirmPassword: "",
        },
    });
    const dispatch = useAppDispatch();
    const validatePassword = (password) => {
        if (password.length < 8)
            return "Moet minimaal 8 tekens bevatten.";
        if (!/[A-Z]+/.test(password))
            return "Moet minstens een hoofdletter bevatten.";
        if (!/[a-z]+/.test(password))
            return "Moet minimaal één kleine letter bevatten.";
        if (!/[0-9]+/.test(password))
            return "Moet ten minste één nummer bevatten.";
        if (!/[ `!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~]/.test(password))
            return "Moet ten minste één speciaal teken bevatten.";
    };
    const validateConfirmPassword = (confirmPassword) => {
        if (watch("newPassword") !== confirmPassword)
            return "Wachtwoord komt niet overeen.";
    };
    useEffect(() => {
        if (open) {
            reset();
        }
    }, [open]);
    const [showCurrentPassword, setShowCurrentPassword] = React.useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = React.useState(false);
    const [showNewPassword, setShowNewPassword] = React.useState(false);
    const handleClickShowPassword = () => setShowCurrentPassword((show) => !show);
    const handleClickShowConfirmPassword = () => setShowConfirmPassword((show) => !show);
    const handleClickShowNewPassword = () => setShowNewPassword((show) => !show);
    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };
    const handleMouseDownNewPassword = (event) => {
        event.preventDefault();
    };
    const handleMouseDownConfirmPassword = (event) => {
        event.preventDefault();
    };
    const onSubmit = (credentials) => {
        dispatch(updateTeacherPasswordAsync(credentials)).then((res) => {
            debugger;
            if (!res.payload.error) {
                onClose();
                reset();
            }
        });
    };
    return (_jsx(Dialog, { fullWidth: true, open: open, onClose: onClose, "aria-labelledby": "alert-dialog-title", "aria-describedby": "alert-dialog-description", children: _jsxs("form", { onSubmit: handleSubmit(onSubmit), id: "password-change-form", noValidate: true, children: [_jsx(DialogTitle, { id: "alert-dialog-title", sx: { color: "primary.main" }, children: "WACHTWOORD WIJZIGEN" }), _jsx(DialogContent, { children: _jsxs(Grid, { container: true, spacing: 4, maxWidth: "small", marginTop: 0, children: [_jsxs(Grid, { item: true, xs: 12, children: [_jsx(Controller, { name: "currentPassword", control: control, rules: {
                                            required: {
                                                value: true,
                                                message: "Dit veld is verplicht.",
                                            },
                                            validate: (value) => validatePassword(value),
                                        }, render: ({ field: { onChange, value } }) => (_jsxs(FormControl, { fullWidth: true, children: [_jsx(InputLabel, { htmlFor: "outlined-password-input", children: "Huidig wachtwoord" }), _jsx(OutlinedInput, { id: "outlined-password-input", name: "password", label: "Huidig wachtwoord", placeholder: "Huidig wachtwoord", onChange: onChange, value: value, type: showCurrentPassword ? "text" : "password", endAdornment: _jsx(InputAdornment, { position: "end", children: _jsx(IconButton, { "aria-label": "toggle password visibility", onClick: handleClickShowPassword, onMouseDown: handleMouseDownPassword, edge: "end", children: showCurrentPassword ? (_jsx(VisibilityOff, {})) : (_jsx(Visibility, {})) }) }) })] })) }), _jsx(WnValidation, { fieldError: errors.currentPassword })] }), _jsxs(Grid, { item: true, xs: 12, children: [_jsx(Controller, { name: "newPassword", control: control, rules: {
                                            required: {
                                                value: true,
                                                message: "Dit veld is verplicht.",
                                            },
                                            validate: (value) => validatePassword(value),
                                        }, render: ({ field: { onChange, value } }) => (_jsxs(FormControl, { fullWidth: true, children: [_jsx(InputLabel, { htmlFor: "outlined-password-input", children: "Nieuw Watchwoord" }), _jsx(OutlinedInput, { id: "outlined-password-input", name: "newPassword", label: " Nieuw Watchwoord", placeholder: "nieuw wachtwoord", onChange: onChange, value: value, type: showNewPassword ? "text" : "password", endAdornment: _jsx(InputAdornment, { position: "end", children: _jsx(IconButton, { "aria-label": "toggle password visibility", onClick: handleClickShowNewPassword, onMouseDown: handleMouseDownNewPassword, edge: "end", children: showCurrentPassword ? (_jsx(VisibilityOff, {})) : (_jsx(Visibility, {})) }) }) })] })) }), _jsx(WnValidation, { fieldError: errors.newPassword })] }), _jsx(Grid, { item: true, xs: 12, children: _jsxs(FormControl, { fullWidth: true, children: [_jsxs(InputLabel, { htmlFor: "outlined-password-input", children: ["Bevestig Wachtwoord", " "] }), _jsx(Controller, { name: "confirmPassword", control: control, rules: {
                                                required: {
                                                    value: true,
                                                    message: "Dit veld is verplicht.",
                                                },
                                                validate: (value) => validateConfirmPassword(value),
                                            }, render: ({ field: { onChange, value } }) => (_jsx(OutlinedInput, { id: "outlined-password-input", name: "password", label: "Bevestig Wachtwoord", placeholder: "bevestig wachtwoord", onChange: onChange, value: value, type: showConfirmPassword ? "text" : "password", endAdornment: _jsx(InputAdornment, { position: "end", children: _jsx(IconButton, { "aria-label": "toggle password visibility", onClick: handleClickShowConfirmPassword, onMouseDown: handleMouseDownConfirmPassword, edge: "end", children: showConfirmPassword ? (_jsx(VisibilityOff, {})) : (_jsx(Visibility, {})) }) }) })) }), _jsx(WnValidation, { fieldError: errors.confirmPassword })] }) })] }) }), _jsxs(DialogActions, { children: [_jsx(Button, { variant: "outlined", color: "primary", onClick: onClose, sx: {
                                boxShadow: "-1.5px 1.5px 0 #F68735",
                                color: "customDark.main",
                                padding: "1.25px 14px",
                                borderRadius: "6px",
                            }, children: "Annuleren" }), _jsx(Button, { variant: "outlined", color: "primary", type: "submit", autoFocus: true, sx: {
                                boxShadow: "-1.5px 1.5px 0 #F68735",
                                color: "customDark.main",
                                padding: "1.25px 14px",
                                borderRadius: "6px",
                            }, children: "Indienen" })] })] }) }));
};
export default ChangePasswordDialog;
