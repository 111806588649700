import { getErrorPayload } from "../../common/helpers/api-helper";
import { createAsyncThunk } from "@reduxjs/toolkit";
import SchoolApi from "@infrastructure/school-api";
import { addSuccessNotification } from "../common/notificationSlice";
export const getSchoolsAsync = createAsyncThunk("school/getSchools", async (_, { rejectWithValue, fulfillWithValue }) => {
    try {
        const response = await SchoolApi.getSchools();
        return fulfillWithValue(response.data);
    }
    catch (error) {
        return rejectWithValue(getErrorPayload(error));
    }
});
export const getSchoolByIdAsync = createAsyncThunk("school/getSchool", async (credentials, { rejectWithValue, fulfillWithValue }) => {
    try {
        const response = await SchoolApi.getSchool(credentials);
        return fulfillWithValue(response.data);
    }
    catch (error) {
        return rejectWithValue(getErrorPayload(error));
    }
});
export const getSchoolDropdownsAsync = createAsyncThunk("school/getSchoolDropdown", async (credentials, { rejectWithValue, fulfillWithValue }) => {
    try {
        const response = await SchoolApi.getSchoolDropdown(credentials);
        return fulfillWithValue(response.data);
    }
    catch (error) {
        return rejectWithValue(getErrorPayload(error));
    }
});
// export const addSchoolAsync = createAsyncThunk<
//   boolean,
//   CreateBoardDto,
//   { rejectValue: ApiError | undefined }
// >("board/addBoard", async (credentials, { rejectWithValue,fulfillWithValue, dispatch }) => {
//   try {
//     const response = await BoardApi.addBoard(credentials);
//     if(response.status === 200) {
//       dispatch(addSuccessNotification('Board Added Successfully.'));
//       return fulfillWithValue(true);
//     } else {
//       return fulfillWithValue(false);
//     }
//   } catch (error) {
//     return rejectWithValue(getErrorPayload(error));
//   }
// });
// export const updateSchoolAsync = createAsyncThunk<
//   boolean,
//   UpdateBoardDto,
//   { rejectValue: ApiError | undefined }
// >("board/updateBoard", async (credentials, { rejectWithValue,fulfillWithValue, dispatch }) => {
//   try {
//     const response = await BoardApi.updateBoard(credentials);
//     if(response.status === 200) {
//       dispatch(addSuccessNotification('Board Updated Successfully.'));
//       return fulfillWithValue(true);
//     } else {
//       return fulfillWithValue(false);
//     }
//   } catch (error) {
//     return rejectWithValue(getErrorPayload(error));
//   }
// });
export const enableDisableSchoolAsync = createAsyncThunk("school/enableDisableSchool", async (credentials, { rejectWithValue, fulfillWithValue, dispatch }) => {
    try {
        const response = await SchoolApi.enableDisableSchool(credentials);
        if (response.status === 200) {
            dispatch(addSuccessNotification('School Updated Successfully.'));
            return fulfillWithValue(true);
        }
        else {
            return fulfillWithValue(false);
        }
    }
    catch (error) {
        return rejectWithValue(getErrorPayload(error));
    }
});
