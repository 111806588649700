import { wijzneusApi } from './wijzneus-api';
const getSeasonsDropdown = () => {
    return wijzneusApi.get(`seasons/dropdown`);
};
const getSeasonsList = (teacherId) => {
    return wijzneusApi.get(`seasons/${teacherId}`);
};
const SeasonApi = {
    getSeasonsDropdown,
    getSeasonsList
};
export default SeasonApi;
