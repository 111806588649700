import { jsx as _jsx } from "react/jsx-runtime";
import { logout } from '@application/features/auth/authSlice';
import { useAppDispatch, useAppSelector } from '@application/store/useStore';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
const ProtectedRoute = ({ children, requiredRoles }) => {
    const location = useLocation();
    const dispatch = useAppDispatch();
    const { currentUser } = useAppSelector((state) => state.auth);
    if (!currentUser)
        return _jsx(Navigate, { to: `${location.pathname.includes('teacher') ? 'teacher-login' : 'student-login'}`, state: { from: location } });
    const userHasRequiredRole = currentUser && requiredRoles.includes(currentUser.role);
    if (!userHasRequiredRole) {
        dispatch(logout());
        return _jsx(Navigate, { to: `${(currentUser.role === 'Teacher' || currentUser.role === 'Supervisor') ? '/teacher-login' : '/student-login'}`, state: { from: location } });
    }
    return children ? children : _jsx(Outlet, {});
};
export default ProtectedRoute;
