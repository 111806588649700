import { wijzneusApi } from './wijzneus-api';
const getTeachers = () => {
    return wijzneusApi.get(`teachers`);
};
const getTeacher = (request) => {
    return wijzneusApi.get(`teachers/${request}`);
};
const getTeacherCourses = (seasonId, themeId) => {
    return wijzneusApi.get(`teacher-course/${seasonId}/${themeId}`);
};
const addTeacherCourse = (request) => {
    return wijzneusApi.post(`teacher-course`, request);
};
const updateTeacherCourse = (request) => {
    return wijzneusApi.put(`teacher-course`, request);
};
const deleteTeacherCourse = (request) => {
    return wijzneusApi.delete(`teacher-course/${request}`);
};
const updateTeacher = (request) => {
    return wijzneusApi.put(`teachers`, request);
};
const enableDisableTeacher = (request) => {
    return wijzneusApi.patch(`teachers`, request);
};
const updateTeacherPassword = (request) => {
    return wijzneusApi.patch(`teacher-password-management`, request);
};
const enableDisableTeacherCourse = (request) => {
    return wijzneusApi.patch(`teacher-course`, request);
};
const enableDisableTeacherAnnualPlanCourse = (request) => {
    return wijzneusApi.patch(`teacher-course/EnableDisableTeacherAnnualPlanCourse`, request);
};
const getTeacherSettings = () => {
    return wijzneusApi.get(`teacher-settings`);
};
const getTeacherFaqs = () => {
    return wijzneusApi.get(`teacher-faqs`);
};
const getTeacherNews = () => {
    return wijzneusApi.get(`teacher-news`);
};
const TeacherApi = {
    getTeachers,
    getTeacher,
    addTeacherCourse,
    updateTeacherCourse,
    deleteTeacherCourse,
    getTeacherCourses,
    updateTeacher,
    enableDisableTeacher,
    enableDisableTeacherCourse,
    enableDisableTeacherAnnualPlanCourse,
    getTeacherSettings,
    getTeacherFaqs,
    getTeacherNews,
    updateTeacherPassword
};
export default TeacherApi;
