import { APIStatus } from '@application/common/enums/api-status';
import { createSlice } from '@reduxjs/toolkit';
import { getCountryDropDownAsync } from './countryThunk';
const initialState = {
    status: APIStatus.IDLE,
};
export const countrySlice = createSlice({
    name: 'country',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getCountryDropDownAsync.pending, (state) => {
            state.status = APIStatus.PENDING;
            state.apiError = undefined;
        })
            .addCase(getCountryDropDownAsync.fulfilled, (state, action) => {
            state.status = APIStatus.IDLE;
            state.countryDropdownList = action.payload;
        })
            .addCase(getCountryDropDownAsync.rejected, (state, action) => {
            state.status = APIStatus.FAILED;
            state.apiError = action.payload;
        });
    }
});
export const {} = countrySlice.actions;
export default countrySlice.reducer;
