import { wijzneusApi } from './wijzneus-api';
const getBoards = () => {
    return wijzneusApi.get(`boards`);
};
const getBoard = (request) => {
    return wijzneusApi.get(`boards/${request}`);
};
const getBoardsDropdown = () => {
    return wijzneusApi.get(`boards/dropdown`);
};
const addBoard = (request) => {
    return wijzneusApi.post(`boards`, request);
};
const updateBoard = (request) => {
    return wijzneusApi.put(`boards`, request);
};
const enableDisableBoard = (request) => {
    return wijzneusApi.patch(`boards`, request);
};
const BoardApi = {
    getBoards,
    getBoard,
    addBoard,
    updateBoard,
    enableDisableBoard,
    getBoardsDropdown
};
export default BoardApi;
