import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import "./style.scss";
import { useNavigate } from "react-router-dom";
import { Alert, Button, Grid, TextField, Typography } from "@mui/material";
import { useEffect } from "react";
import WnValidation from "@ui/components/wn-validation";
import { Controller, useForm } from "react-hook-form";
import { useAppDispatch, useAppSelector } from "@application/store/useStore";
import { kidsLoginAsync } from "@application/features/auth/authThunk";
import { getSeasonListAsync } from "@application/features/season/seasonThunk";
import { resetLoggedInUserRole, } from "@application/features/auth/authSlice";
const StudentLogin = () => {
    const { currentUser, apiError } = useAppSelector((state) => state.auth);
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    useEffect(() => {
        if (currentUser) {
            if (["Student"].includes(currentUser.role)) {
                const teacherId = currentUser.id;
                dispatch(getSeasonListAsync(teacherId)).then((result) => {
                    const seasonList = result.payload;
                    if (seasonList && seasonList.length > 0) {
                        const season = seasonList.find((x) => x.name.toLocaleLowerCase() === "autumn");
                        if (season) {
                            navigate(`/student/courses/${season.id}/${season.themeId}`);
                        }
                    }
                });
            }
        }
    }, [currentUser, dispatch, navigate]);
    useEffect(() => {
        if (currentUser) {
            if (currentUser.role === "Teacher" || currentUser.role === "Supervisor") {
                navigate("/");
            }
        }
    }, [currentUser, navigate]);
    useEffect(() => {
        if (currentUser === undefined) {
            dispatch(resetLoggedInUserRole());
        }
    }, [dispatch, currentUser]);
    const { control, formState: { errors }, handleSubmit, } = useForm({
        defaultValues: {
            voucherCode: "",
        },
    });
    const onSubmit = (credentials) => dispatch(kidsLoginAsync(credentials));
    return (_jsx(_Fragment, { children: _jsx("div", { className: "form-container", children: _jsx("form", { onSubmit: handleSubmit(onSubmit), id: "student-login-form", noValidate: true, children: _jsxs(Grid, { container: true, spacing: 2, children: [apiError && apiError.status === 401 && (_jsxs(_Fragment, { children: [_jsx(Grid, { item: true, xs: 12, children: _jsx(Alert, { severity: "error", children: apiError.error }) }), _jsx("br", {})] })), _jsxs(Grid, { item: true, xs: 12, children: [_jsx(Typography, { variant: "h4", sx: { fontFamily: "Marykate" }, children: "code" }), _jsx(Controller, { name: "voucherCode", control: control, rules: {
                                        required: {
                                            value: true,
                                            message: 'Dit veld is verplicht.',
                                        },
                                    }, render: ({ field: { onChange, value } }) => (_jsx(TextField, { className: "login-field", hiddenLabel: true, fullWidth: true, id: "outlined-username-input", placeholder: "Wat is je klascode? Schrijf hier", type: "text", variant: "outlined", onChange: onChange, value: value, sx: { width: "100%" }, inputProps: { min: 0, style: { textAlign: "center" } } })) }), _jsx(WnValidation, { fieldError: errors.voucherCode })] }), _jsx(Grid, { item: true, xs: 12, children: _jsx("div", { className: "teacher-login-go-btn-container", style: { backgroundColor: "#64CAFF", padding: "4px" }, children: _jsx(Button, { className: "btn", variant: "contained", type: "submit", sx: {
                                        padding: "18px 36px",
                                        color: "#00558A",
                                        border: "3px solid #00558A",
                                        backgroundColor: "#64CAFF",
                                        lineHeight: "1",
                                        minWidth: "165px",
                                    }, children: "Gaan" }) }) })] }) }) }) }));
};
export default StudentLogin;
