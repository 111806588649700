import { getErrorPayload } from "../../common/helpers/api-helper";
import { createAsyncThunk } from "@reduxjs/toolkit";
import TeacherApi from "@infrastructure/teacher-api";
import { addSuccessNotification } from "../common/notificationSlice";
import { setIsCourseLoaded } from "./teacherSlice";
import { translate } from "@application/common/helpers/translate";
export const getTeachersAsync = createAsyncThunk("teacher/getTeachers", async (_, { rejectWithValue, fulfillWithValue }) => {
    try {
        const response = await TeacherApi.getTeachers();
        return fulfillWithValue(response.data);
    }
    catch (error) {
        return rejectWithValue(getErrorPayload(error));
    }
});
export const getTeacherByIdAsync = createAsyncThunk("teacher/getTeacher", async (credentials, { rejectWithValue, fulfillWithValue }) => {
    try {
        const response = await TeacherApi.getTeacher(credentials);
        return fulfillWithValue(response.data);
    }
    catch (error) {
        return rejectWithValue(getErrorPayload(error));
    }
});
export const deleteTeacherCourseAsync = createAsyncThunk("teacher/deleteTeacherCourse", async (credentials, { rejectWithValue, fulfillWithValue, dispatch }) => {
    try {
        const response = await TeacherApi.deleteTeacherCourse(credentials);
        if (response.status === 200) {
            dispatch(addSuccessNotification(translate("cta.deleteModuleSuccess").replace("{module}", translate("lessons.courseItems.courseItem"))));
            return fulfillWithValue(true);
        }
        else {
            return fulfillWithValue(false);
        }
    }
    catch (error) {
        return rejectWithValue(getErrorPayload(error));
    }
});
export const getTeacherCoursesAsync = createAsyncThunk("teacher/getTeacherCourses", async (credentials, { dispatch, rejectWithValue, fulfillWithValue }) => {
    try {
        const response = await TeacherApi.getTeacherCourses(credentials.seasonId, credentials.themeId);
        if (response.status === 200) {
            dispatch(setIsCourseLoaded(false));
        }
        return fulfillWithValue(response.data);
    }
    catch (error) {
        return rejectWithValue(getErrorPayload(error));
    }
});
export const getTeacherSettingsAsync = createAsyncThunk("teacher/getTeacherSettings", async (credentials, { rejectWithValue, fulfillWithValue }) => {
    try {
        const response = await TeacherApi.getTeacherSettings();
        return fulfillWithValue(response.data);
    }
    catch (error) {
        return rejectWithValue(getErrorPayload(error));
    }
});
export const getTeacherFaqsAsync = createAsyncThunk("teacher/getTeacherFaqs", async (credentials, { rejectWithValue, fulfillWithValue }) => {
    try {
        const response = await TeacherApi.getTeacherFaqs();
        return fulfillWithValue(response.data);
    }
    catch (error) {
        return rejectWithValue(getErrorPayload(error));
    }
});
export const getTeacherNewsAsync = createAsyncThunk("teacher/getTeacherNews", async (credentials, { rejectWithValue, fulfillWithValue }) => {
    try {
        const response = await TeacherApi.getTeacherNews();
        return fulfillWithValue(response.data);
    }
    catch (error) {
        return rejectWithValue(getErrorPayload(error));
    }
});
export const addTeacherCourseAsync = createAsyncThunk("teacher/addTeacherCourse", async (credentials, { rejectWithValue, fulfillWithValue, dispatch }) => {
    try {
        const response = await TeacherApi.addTeacherCourse(credentials);
        if (response.status === 200) {
            dispatch(addSuccessNotification(translate("cta.addModuleSuccess").replace("{module}", translate("lessons.courseItems.courseItem"))));
            return fulfillWithValue(true);
        }
        else {
            return fulfillWithValue(false);
        }
    }
    catch (error) {
        return rejectWithValue(getErrorPayload(error));
    }
});
export const updateTeacherCourseAsync = createAsyncThunk("teacher/updateTeacherCourse", async (credentials, { rejectWithValue, fulfillWithValue, dispatch }) => {
    try {
        const response = await TeacherApi.updateTeacherCourse(credentials);
        if (response.status === 200) {
            dispatch(addSuccessNotification(translate("cta.updateModuleSuccess").replace("{module}", translate("lessons.courseItems.courseItem"))));
            return fulfillWithValue(true);
        }
        else {
            return fulfillWithValue(false);
        }
    }
    catch (error) {
        return rejectWithValue(getErrorPayload(error));
    }
});
export const updateTeacherAsync = createAsyncThunk("teacher/updateTeacher", async (credentials, { rejectWithValue, fulfillWithValue, dispatch }) => {
    try {
        const response = await TeacherApi.updateTeacher(credentials);
        if (response.status === 200) {
            dispatch(addSuccessNotification(translate("cta.updateModuleSuccess").replace("{module}", translate("teachers&Supervisors.teacher"))));
            return fulfillWithValue(true);
        }
        else {
            return fulfillWithValue(false);
        }
    }
    catch (error) {
        return rejectWithValue(getErrorPayload(error));
    }
});
export const enableDisableTeacherAsync = createAsyncThunk("teacher/enableDisableTeacher", async (credentials, { rejectWithValue, fulfillWithValue, dispatch }) => {
    try {
        const response = await TeacherApi.enableDisableTeacher(credentials);
        if (response.status === 200) {
            dispatch(addSuccessNotification(translate("cta.updateModuleSuccess").replace("{module}", translate("teachers&Supervisors.teacher"))));
            return fulfillWithValue(true);
        }
        else {
            return fulfillWithValue(false);
        }
    }
    catch (error) {
        return rejectWithValue(getErrorPayload(error));
    }
});
export const updateTeacherPasswordAsync = createAsyncThunk("teacher/updateTeacherPassword", async (credentials, { rejectWithValue, fulfillWithValue, dispatch }) => {
    try {
        const response = await TeacherApi.updateTeacherPassword(credentials);
        if (response.status === 200) {
            dispatch(addSuccessNotification(translate("cta.updateModuleSuccess").replace("{module}", translate("teachers&Supervisors.teacher"))));
            return fulfillWithValue(true);
        }
        else {
            return fulfillWithValue(false);
        }
    }
    catch (error) {
        return rejectWithValue(getErrorPayload(error));
    }
});
export const enableDisableTeacherCourseAsync = createAsyncThunk("teacher/enableDisableTeacherCourseAsync", async (credentials, { rejectWithValue, fulfillWithValue, dispatch }) => {
    try {
        const response = await TeacherApi.enableDisableTeacherCourse(credentials);
        if (response.status === 200) {
            dispatch(addSuccessNotification(translate("cta.updateModuleSuccess").replace("{module}", translate("lessons.course"))));
            return fulfillWithValue(true);
        }
        else {
            return fulfillWithValue(false);
        }
    }
    catch (error) {
        return rejectWithValue(getErrorPayload(error));
    }
});
export const enableDisableTeacherAnnualPlanCourseAsync = createAsyncThunk("teacher/enableDisableTeacherAnnualPlanCourseAsync", async (credentials, { rejectWithValue, fulfillWithValue, dispatch }) => {
    try {
        const response = await TeacherApi.enableDisableTeacherAnnualPlanCourse(credentials);
        if (response.status === 200) {
            dispatch(addSuccessNotification(translate("cta.updateModuleSuccess").replace("{module}", translate("lessons.course"))));
            return fulfillWithValue(true);
        }
        else {
            return fulfillWithValue(false);
        }
    }
    catch (error) {
        return rejectWithValue(getErrorPayload(error));
    }
});
