import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import "./style.scss";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Button, Grid, IconButton, InputAdornment, OutlinedInput, Typography } from "@mui/material";
import { useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import { useAppDispatch, useAppSelector } from "@application/store/useStore";
import WnValidation from "@ui/components/wn-validation";
import { logout } from "@application/features/auth/authSlice";
import { forcedPasswordChangedAsync } from "@application/features/auth/authThunk";
import { VisibilityOff, Visibility } from "@mui/icons-material";
import React from "react";
const PasswordReset = () => {
    const { apiError } = useAppSelector((state) => state.auth);
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const token = searchParams.get("token");
    const email = searchParams.get("email");
    const [showPassword, setShowPassword] = React.useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = React.useState(false);
    const handleClickShowPassword = () => setShowPassword((show) => !show);
    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };
    const handleClickShowConfirmPassword = () => setShowConfirmPassword((show) => !show);
    const handleMouseDownConfirmPassword = (event) => {
        event.preventDefault();
    };
    const { control, formState: { errors }, handleSubmit, setValue, watch, } = useForm({
        defaultValues: {
            email: "",
            password: "",
            confirmPassword: "",
            token: "",
        },
    });
    useEffect(() => {
        if (email && token) {
            setValue("email", decodeURIComponent(email));
            setValue("token", token.replace(/\s/g, "+"));
        }
    }, [email, setValue, token]);
    const onSubmit = (credentials) => {
        dispatch(logout());
        dispatch(forcedPasswordChangedAsync(credentials)).then((res) => {
            if (res.payload === true) {
                navigate(`/teacher-login`);
            }
        });
    };
    const validatePassword = (password) => {
        if (password.length < 8)
            return 'Moet minimaal 8 tekens bevatten.';
        if (!/[A-Z]+/.test(password))
            return 'Moet minstens een hoofdletter bevatten.';
        if (!/[a-z]+/.test(password))
            return 'Moet minimaal één kleine letter bevatten.';
        if (!/[0-9]+/.test(password))
            return 'Moet ten minste één nummer bevatten.';
        if (!/[ `!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~]/.test(password))
            return 'Moet ten minste één speciaal teken bevatten.';
    };
    const validateConfirmPassword = (confirmPassword) => {
        if (watch("password") !== confirmPassword)
            return 'Wachtwoord komt niet overeen.';
    };
    return (_jsx(_Fragment, { children: _jsx("div", { className: "form-container", children: _jsx("form", { onSubmit: handleSubmit(onSubmit), id: "reset-password-form", noValidate: true, children: _jsxs(Grid, { container: true, spacing: 2, children: [_jsxs(Grid, { item: true, xs: 12, children: [_jsx(Typography, { variant: "h4", sx: { fontFamily: "Marykate" }, children: "wachtwoord" }), _jsx(Controller, { name: "password", control: control, rules: {
                                        required: {
                                            value: true,
                                            message: 'Dit veld is verplicht.',
                                        },
                                        validate: (value) => validatePassword(value),
                                    }, render: ({ field: { onChange, value } }) => (_jsx(OutlinedInput, { className: "login-field", id: "outlined-password-input", name: "password", placeholder: "wachtwoord", onChange: onChange, value: value, type: showPassword ? "text" : "password", endAdornment: _jsx(InputAdornment, { position: "end", children: _jsx(IconButton, { "aria-label": "toggle password visibility", onClick: handleClickShowPassword, onMouseDown: handleMouseDownPassword, edge: "end", children: showPassword ? (_jsx(VisibilityOff, {})) : (_jsx(Visibility, {})) }) }) })) }), _jsx(WnValidation, { fieldError: errors.password })] }), _jsxs(Grid, { item: true, xs: 12, children: [_jsx(Typography, { variant: "h4", sx: { fontFamily: "Marykate" }, children: "bevestig wachtwoord" }), _jsx(Controller, { name: "confirmPassword", control: control, rules: {
                                        required: {
                                            value: true,
                                            message: 'Dit veld is verplicht.',
                                        },
                                        validate: (value) => validateConfirmPassword(value),
                                    }, render: ({ field: { onChange, value } }) => (_jsx(OutlinedInput, { className: "login-field", id: "outlined-password-input", name: "password", placeholder: "bevestig wachtwoord", onChange: onChange, value: value, type: showConfirmPassword ? "text" : "password", endAdornment: _jsx(InputAdornment, { position: "end", children: _jsx(IconButton, { "aria-label": "toggle password visibility", onClick: handleClickShowConfirmPassword, onMouseDown: handleMouseDownConfirmPassword, edge: "end", children: showConfirmPassword ? (_jsx(VisibilityOff, {})) : (_jsx(Visibility, {})) }) }) })) }), _jsx(WnValidation, { fieldError: errors.confirmPassword })] }), _jsx(Grid, { item: true, xs: 12, children: _jsx("div", { className: "teacher-login-go-btn-container", style: { backgroundColor: "#64CAFF", padding: "4px" }, children: _jsx(Button, { className: "btn", variant: "contained", type: "submit", sx: {
                                        padding: { lg: "18px 36px" },
                                        color: "#00558A",
                                        border: "3px solid #00558A",
                                        backgroundColor: "#64CAFF",
                                        lineHeight: "1",
                                        minWidth: "165px",
                                    }, children: "Indienen" }) }) })] }) }) }) }));
};
export default PasswordReset;
