import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import { useEffect, useState } from "react";
import CircularProgress from "@mui/material/CircularProgress";
import "./style.scss";
import { Box } from "@mui/material";
const Loading = ({ isLoading }) => {
    const [showLoading, setShowLoading] = useState(false);
    useEffect(() => {
        const wait = (ms) => new Promise((resolve) => {
            setTimeout(() => resolve(), ms);
        });
        wait(1000).then(() => {
            setShowLoading(isLoading);
        });
    }, [isLoading]);
    return showLoading ? (_jsx(_Fragment, { children: _jsx("div", { id: "backdrop", children: _jsx("div", { className: "text-center loading", children: _jsx("div", { className: "spinner-border", role: "status", children: _jsx(Box, { sx: { display: "flex" }, children: _jsx(CircularProgress, {}) }) }) }) }) })) : (_jsx(_Fragment, {}));
};
export default Loading;
