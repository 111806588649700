import LocalStorageService from '../../../infrastructure/local-storage-service';
import jwtDecode from 'jwt-decode';
import { ClaimTypes } from '../constants/claim-types';
export const getCurrentUserFromJwt = (token) => {
    const authToken = token || LocalStorageService.getAccessToken();
    if (authToken != null && authToken !== undefined) {
        const decodedJWT = jwtDecode(authToken);
        const currentUser = {
            accessToken: authToken,
            id: decodedJWT[ClaimTypes.NameIdentifier],
            name: decodedJWT[ClaimTypes.Name],
            role: decodedJWT[ClaimTypes.Role],
            email: decodedJWT[ClaimTypes.Email],
            voucherCode: decodedJWT[ClaimTypes.voucherCode],
        };
        return currentUser;
    }
    return undefined;
};
