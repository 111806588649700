import { getErrorPayload } from "../../common/helpers/api-helper";
import { createAsyncThunk } from "@reduxjs/toolkit";
import KidsCourseApi from "@infrastructure/kid-course-api";
export const getKidCoursesAsync = createAsyncThunk("kid/getKidCourses", async (credentials, { rejectWithValue, fulfillWithValue }) => {
    try {
        const response = await KidsCourseApi.getkidsCourses(credentials);
        return fulfillWithValue(response.data);
    }
    catch (error) {
        return rejectWithValue(getErrorPayload(error));
    }
});
