import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import "./style.scss";
import { Alert, Button, Grid, TextField, Typography } from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import { useAppDispatch, useAppSelector } from "@application/store/useStore";
import { forgotPasswordAsync } from "@application/features/auth/authThunk";
import WnValidation from "@ui/components/wn-validation";
import { clearApiError, } from "@application/features/auth/authSlice";
import { useEffect } from "react";
const ForgetPassword = () => {
    const { apiError } = useAppSelector((state) => state.auth);
    const dispatch = useAppDispatch();
    useEffect(() => {
        dispatch(clearApiError());
    }, [dispatch]);
    const { control, formState: { errors }, handleSubmit, } = useForm({
        defaultValues: {
            email: "",
        },
    });
    const onSubmit = (credentials) => dispatch(forgotPasswordAsync(credentials));
    return (_jsx(_Fragment, { children: _jsx("div", { className: "form-container", children: _jsx("form", { onSubmit: handleSubmit(onSubmit), id: "forget-password-form", noValidate: true, children: _jsxs(Grid, { container: true, spacing: 2, children: [apiError && apiError.status === 401 && (_jsxs(_Fragment, { children: [_jsx(Grid, { item: true, xs: 12, children: _jsx(Alert, { severity: "error", children: apiError.error }) }), _jsx("br", {})] })), _jsxs(Grid, { item: true, xs: 12, children: [_jsx(Typography, { variant: "h4", sx: { fontFamily: "Marykate" }, children: "e-mail" }), _jsx(Controller, { name: "email", control: control, rules: {
                                        required: {
                                            value: true,
                                            message: 'Dit veld is verplicht.',
                                        },
                                        pattern: {
                                            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                            message: 'E-mailadres is niet geldig.',
                                        },
                                    }, render: ({ field: { onChange, value } }) => (_jsx(TextField, { fullWidth: true, hiddenLabel: true, className: "login-field", id: "outlined-username-input", placeholder: "e-mail", type: "email", variant: "outlined", onChange: onChange, value: value, sx: { width: "100%" }, inputProps: { min: 0, style: { textAlign: "center" } } })) }), _jsx(WnValidation, { fieldError: errors.email })] }), _jsx(Grid, { item: true, xs: 12, children: _jsx("div", { className: "teacher-login-go-btn-container", style: { backgroundColor: "#64CAFF", padding: "4px" }, children: _jsx(Button, { className: "btn", variant: "contained", type: "submit", sx: {
                                        padding: { lg: "18px 36px" },
                                        color: "#00558A",
                                        border: "3px solid #00558A",
                                        backgroundColor: "#64CAFF",
                                        lineHeight: "1",
                                        minWidth: "165px",
                                    }, children: "Gaan" }) }) })] }) }) }) }));
};
export default ForgetPassword;
