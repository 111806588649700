import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import "./style.scss";
import { useNavigate } from "react-router-dom";
import { Alert, Button, Grid, IconButton, InputAdornment, OutlinedInput, TextField, Typography, } from "@mui/material";
import React, { useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import { useAppDispatch, useAppSelector } from "@application/store/useStore";
import { loginAsync } from "@application/features/auth/authThunk";
import WnValidation from "@ui/components/wn-validation";
import { getSeasonListAsync } from "@application/features/season/seasonThunk";
import { clearApiError, resetLoggedInUserRole, } from "@application/features/auth/authSlice";
import { Visibility, VisibilityOff } from "@mui/icons-material";
const TeacherLogin = () => {
    const { currentUser, apiError } = useAppSelector((state) => state.auth);
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const [showPassword, setShowPassword] = React.useState(false);
    const handleClickShowPassword = () => setShowPassword((show) => !show);
    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };
    useEffect(() => {
        if (currentUser) {
            if (["Teacher", "Supervisor"].includes(currentUser.role)) {
                const teacherId = currentUser?.id;
                dispatch(getSeasonListAsync(teacherId)).then((result) => {
                    const seasonList = result.payload;
                    if (seasonList && seasonList.length > 0) {
                        const season = seasonList.find((x) => x.name.toLocaleLowerCase() === "autumn");
                        if (season) {
                            navigate(`/teacher/courses/${season.id}/${season.themeId}`);
                        }
                    }
                });
            }
        }
    }, [currentUser, dispatch, navigate]);
    useEffect(() => {
        if (currentUser) {
            if (currentUser.role === "Student") {
                navigate("/");
            }
        }
    }, [currentUser, navigate]);
    useEffect(() => {
        if (currentUser === undefined) {
            dispatch(resetLoggedInUserRole());
        }
    }, [dispatch, currentUser]);
    useEffect(() => {
        dispatch(clearApiError());
    }, [dispatch]);
    const { control, formState: { errors }, handleSubmit, } = useForm({
        defaultValues: {
            email: "",
            password: "",
            rememberMe: false,
        },
    });
    const onSubmit = (credentials) => dispatch(loginAsync(credentials));
    return (_jsx(_Fragment, { children: _jsx("div", { className: "form-container", children: _jsx("form", { onSubmit: handleSubmit(onSubmit), id: "login-form", noValidate: true, children: _jsxs(Grid, { container: true, spacing: 2, children: [apiError && apiError.status === 401 && (_jsxs(_Fragment, { children: [_jsx(Grid, { item: true, xs: 12, children: _jsx(Alert, { severity: "error", children: apiError.error }) }), _jsx("br", {})] })), _jsxs(Grid, { item: true, xs: 12, children: [_jsx(Typography, { variant: "h4", sx: { fontFamily: "Marykate" }, children: "e-mail" }), _jsx(Controller, { name: "email", control: control, rules: {
                                        required: {
                                            value: true,
                                            message: 'Dit veld is verplicht.',
                                        },
                                        pattern: {
                                            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                            message: 'E-mailadres is niet geldig.',
                                        },
                                    }, render: ({ field: { onChange, value } }) => (_jsx(TextField, { className: "login-field", hiddenLabel: true, fullWidth: true, id: "outlined-username-input", placeholder: "e-mail", type: "email", variant: "outlined", onChange: onChange, value: value, sx: { width: "100%" } })) }), _jsx(WnValidation, { fieldError: errors.email })] }), _jsxs(Grid, { item: true, xs: 12, children: [_jsx(Typography, { variant: "h4", sx: { fontFamily: "Marykate" }, children: "wachtwoord" }), _jsx(Controller, { name: "password", control: control, rules: {
                                        required: {
                                            value: true,
                                            message: 'Dit veld is verplicht.',
                                        },
                                    }, render: ({ field: { onChange, value } }) => (_jsx(OutlinedInput, { className: "login-field", sx: { width: "100%" }, fullWidth: true, id: "outlined-password-input", type: showPassword ? "text" : "password", name: "password", placeholder: "wachtwoord", onChange: onChange, value: value, endAdornment: _jsx(InputAdornment, { position: "end", children: _jsx(IconButton, { "aria-label": "toggle password visibility", onClick: handleClickShowPassword, onMouseDown: handleMouseDownPassword, edge: "end", children: showPassword ? (_jsx(VisibilityOff, {})) : (_jsx(Visibility, {})) }) }), label: "Password" })) }), _jsx(WnValidation, { fieldError: errors.password })] }), _jsx(Grid, { item: true, xs: 12, children: _jsx("div", { className: "teacher-login-go-btn-container", style: { backgroundColor: "#64CAFF", padding: "4px" }, children: _jsx(Button, { className: "btn", variant: "contained", type: "submit", sx: {
                                        padding: { lg: "18px 36px" },
                                        color: "#00558A",
                                        border: "3px solid #00558A",
                                        backgroundColor: "#64CAFF",
                                        lineHeight: "1",
                                        minWidth: "165px",
                                    }, children: "Gaan" }) }) })] }) }) }) }));
};
export default TeacherLogin;
