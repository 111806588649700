import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { ThemeProvider } from "@emotion/react";
import "./style.scss";
import { outlinedInputClasses } from "@mui/material";
import { Outlet } from "react-router-dom";
import { createTheme } from "@mui/material/styles";
import { CssBaseline } from "@mui/material";
import { useAppSelector } from "@application/store/useStore";
import FaqHeader from "./faqHeader";
import { useLocation } from 'react-router-dom';
import { useEffect, useState } from "react";
const customColors = {
    winter: {
        main: "#2D8AC5",
        light: "#E9DB5D",
        dark: "#A29415",
        contrastText: "#fff",
    },
    inputBg: {
        main: "#F7F7F7",
        light: "#E9DB5D",
        dark: "#A29415",
        contrastText: "#000",
    },
    customDark: {
        main: "#343233",
        light: "#E9DB5D",
        dark: "#A29415",
        contrastText: "#fff",
    },
    white: {
        main: "#fff",
        light: "#fff",
        dark: "#fff",
        contrastText: "#000",
    },
};
const FaqNewsLayout = () => {
    const theme = createTheme({
        palette: {
            primary: {
                main: "#F68735",
                light: "#42a5f5",
                dark: "#1565c0",
                contrastText: "#fff",
            },
            winter: {
                main: customColors.winter.main,
                light: customColors.winter.light,
                dark: customColors.winter.dark,
                contrastText: customColors.winter.contrastText,
            },
            inputBg: {
                main: customColors.inputBg.main,
                light: customColors.inputBg.light,
                dark: customColors.inputBg.dark,
                contrastText: customColors.inputBg.contrastText,
            },
            customDark: {
                main: customColors.customDark.main,
                light: customColors.customDark.light,
                dark: customColors.customDark.dark,
                contrastText: customColors.customDark.contrastText,
            },
            white: {
                main: customColors.white.main,
                light: customColors.white.light,
                dark: customColors.white.dark,
                contrastText: customColors.white.contrastText,
            },
            secondary: {
                main: "#ffba00",
            },
        },
        shape: {
            borderRadius: 18,
        },
        typography: {
            fontFamily: '"Quicksand", sans-serif',
            button: {
                fontSize: "1rem",
                fontWeight: "normal",
            },
        },
        // Override button hover color
        components: {
            MuiButton: {
                styleOverrides: {
                    root: {
                        "&:hover": {
                            backgroundColor: "winter.main",
                        },
                    },
                },
            },
            MuiTextField: {
                styleOverrides: {
                    root: {
                        '--TextField-brandBorderColor': '#E0E3E7',
                        '--TextField-brandBorderHoverColor': '#B2BAC2',
                        '--TextField-brandBorderFocusedColor': '#6F7E8C',
                        '& label.Mui-focused': {
                            color: 'var(--TextField-brandBorderFocusedColor)',
                        },
                    },
                },
            },
            MuiOutlinedInput: {
                styleOverrides: {
                    notchedOutline: {
                        borderColor: 'var(--TextField-brandBorderColor)',
                    },
                    root: {
                        [`&:hover .${outlinedInputClasses.notchedOutline}`]: {
                            borderColor: 'var(--TextField-brandBorderHoverColor)',
                        },
                        [`&.Mui-focused .${outlinedInputClasses.notchedOutline}`]: {
                            borderColor: 'var(--TextField-brandBorderFocusedColor)',
                        },
                    },
                },
            },
        },
    });
    const { activeSeason } = useAppSelector((state) => state.season);
    const [isAnnualPlan, setIsAnnualPlan] = useState(false);
    const location = useLocation();
    useEffect(() => {
        if (location.pathname.includes('annual-plan')) {
            setIsAnnualPlan(true);
        }
        else {
            setIsAnnualPlan(false);
        }
    }, [location]);
    return (_jsxs(ThemeProvider, { theme: theme, children: [_jsx(CssBaseline, {}), _jsx("div", { className: `kids-main ${activeSeason}`, children: _jsxs("div", { className: "inner", children: [_jsx(FaqHeader, {}), _jsx("div", { className: `courses-container ${isAnnualPlan ? 'full-width' : ''}`, children: _jsx(Outlet, {}) })] }) })] }));
};
export default FaqNewsLayout;
