import { getErrorPayload } from "../../common/helpers/api-helper";
import AuthApi from "../../../infrastructure/auth-api";
import LocalStorageService from "../../../infrastructure/local-storage-service";
import { createAsyncThunk } from "@reduxjs/toolkit";
import KidsAuthApi from "@infrastructure/kid-auth-api";
import { addSuccessNotification } from "../common/notificationSlice";
import { translate } from "@application/common/helpers/translate";
export const loginAsync = createAsyncThunk("auth/login", async (credentials, { dispatch, rejectWithValue, fulfillWithValue }) => {
    try {
        const response = await AuthApi.login(credentials);
        return fulfillWithValue(response.data);
    }
    catch (error) {
        const apiError = getErrorPayload(error);
        //dispatch(addErrorNotification(apiError.error ?? "Internal server error"));
        return rejectWithValue(apiError);
    }
});
export const refreshTokenAsync = createAsyncThunk("auth/refreshToken", async (_, { rejectWithValue }) => {
    try {
        const refreshTokenRequest = {
            accessToken: LocalStorageService.getAccessToken(),
            refreshToken: LocalStorageService.getRefreshToken(),
        };
        const response = await AuthApi.getRefreshToken(refreshTokenRequest);
        return response.data;
    }
    catch (error) {
        return rejectWithValue(getErrorPayload(error));
    }
});
export const signUpAsync = createAsyncThunk("auth/signUp", async (user, { rejectWithValue, fulfillWithValue }) => {
    try {
        const response = await AuthApi.signUp(user);
        return fulfillWithValue(response.data);
    }
    catch (error) {
        return rejectWithValue(getErrorPayload(error));
    }
});
export const confirmEmailAsync = createAsyncThunk("auth/confirmEmail", async (confirmation, { rejectWithValue, fulfillWithValue }) => {
    try {
        const response = await AuthApi.confirmEmail(confirmation);
        return fulfillWithValue(response.data);
    }
    catch (error) {
        return rejectWithValue(getErrorPayload(error));
    }
});
export const forgotPasswordAsync = createAsyncThunk("auth/forgotPassword", async (input, { rejectWithValue, fulfillWithValue, dispatch }) => {
    try {
        const response = await AuthApi.forgotPassword(input);
        dispatch(addSuccessNotification(response.data));
        return fulfillWithValue(response.data);
    }
    catch (error) {
        return rejectWithValue(getErrorPayload(error));
    }
});
export const forcedPasswordChangedAsync = createAsyncThunk("auth/forcedPasswordChanged", async (input, { rejectWithValue, fulfillWithValue, dispatch }) => {
    try {
        const response = await AuthApi.forcedPasswordChanged(input);
        if (response.status === 200) {
            dispatch(addSuccessNotification(translate("account.passwordResetMessage")));
            return fulfillWithValue(true);
        }
        else {
            return fulfillWithValue(false);
        }
    }
    catch (error) {
        return rejectWithValue(getErrorPayload(error));
    }
});
export const resetPasswordAsync = createAsyncThunk("auth/resetPassword", async (input, { rejectWithValue, fulfillWithValue }) => {
    try {
        const response = await AuthApi.resetPassword(input);
        return fulfillWithValue(response.data);
    }
    catch (error) {
        return rejectWithValue(getErrorPayload(error));
    }
});
export const twoFactorLoginAsync = createAsyncThunk("auth/twoFactorLogin", async (input, { dispatch, rejectWithValue, fulfillWithValue }) => {
    try {
        const response = await AuthApi.twoFactorLoginAsync(input);
        return fulfillWithValue(response.data);
    }
    catch (error) {
        const errorValue = getErrorPayload(error);
        return rejectWithValue(errorValue);
    }
});
export const verifyTokenAsync = createAsyncThunk("auth/verifyToken", async (VerifyTokenDto, { rejectWithValue, fulfillWithValue }) => {
    try {
        const response = await AuthApi.verifyToken(VerifyTokenDto);
        return fulfillWithValue(response.data);
    }
    catch (error) {
        return rejectWithValue(getErrorPayload(error));
    }
});
export const kidsLoginAsync = createAsyncThunk("vouchersAuthentication/login", async (credentials, { dispatch, rejectWithValue, fulfillWithValue }) => {
    try {
        const response = await KidsAuthApi.kidsLogin(credentials);
        return fulfillWithValue(response.data);
    }
    catch (error) {
        const apiError = getErrorPayload(error);
        //dispatch(addErrorNotification(apiError.error ?? "Internal server error"));
        return rejectWithValue(apiError);
    }
});
