import { APIStatus } from '@application/common/enums/api-status';
import { createSlice } from '@reduxjs/toolkit';
import { addThemeAsync, enableDisableThemeAsync, getThemeByIdAsync, getThemeBySeasonandTeacherAsync, getThemesAsync, updateThemeAsync, enableDisableTeacherThemeAsync } from './themeThunk';
const initialState = {
    status: APIStatus.IDLE,
    themes: [],
    themeList: []
};
export const themeSlice = createSlice({
    name: 'theme',
    initialState,
    reducers: {
        setFeaturedImage(state, action) {
            state.featuredImage = action.payload;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(getThemesAsync.pending, (state) => {
            state.status = APIStatus.PENDING;
            state.apiError = undefined;
        })
            .addCase(getThemesAsync.fulfilled, (state, action) => {
            state.status = APIStatus.IDLE;
            state.themes = action.payload;
        })
            .addCase(getThemesAsync.rejected, (state, action) => {
            state.status = APIStatus.FAILED;
            state.apiError = action.payload;
        });
        builder
            .addCase(getThemeByIdAsync.pending, (state) => {
            state.status = APIStatus.PENDING;
            state.apiError = undefined;
        })
            .addCase(getThemeByIdAsync.fulfilled, (state, action) => {
            state.status = APIStatus.IDLE;
            state.theme = action.payload;
        })
            .addCase(getThemeByIdAsync.rejected, (state, action) => {
            state.status = APIStatus.FAILED;
            state.apiError = action.payload;
        });
        builder
            .addCase(getThemeBySeasonandTeacherAsync.pending, (state) => {
            state.status = APIStatus.PENDING;
            state.apiError = undefined;
        })
            .addCase(getThemeBySeasonandTeacherAsync.fulfilled, (state, action) => {
            state.status = APIStatus.IDLE;
            state.themeList = action.payload;
        })
            .addCase(getThemeBySeasonandTeacherAsync.rejected, (state, action) => {
            state.status = APIStatus.FAILED;
            state.apiError = action.payload;
        });
        builder
            .addCase(addThemeAsync.pending, (state) => {
            state.status = APIStatus.PENDING;
            state.apiError = undefined;
        })
            .addCase(addThemeAsync.fulfilled, (state, action) => {
            state.status = APIStatus.IDLE;
        })
            .addCase(addThemeAsync.rejected, (state, action) => {
            state.status = APIStatus.FAILED;
            state.apiError = action.payload;
        });
        builder
            .addCase(updateThemeAsync.pending, (state) => {
            state.status = APIStatus.PENDING;
            state.apiError = undefined;
        })
            .addCase(updateThemeAsync.fulfilled, (state, action) => {
            state.status = APIStatus.IDLE;
        })
            .addCase(updateThemeAsync.rejected, (state, action) => {
            state.status = APIStatus.FAILED;
            state.apiError = action.payload;
        });
        builder
            .addCase(enableDisableThemeAsync.pending, (state) => {
            state.status = APIStatus.PENDING;
            state.apiError = undefined;
        })
            .addCase(enableDisableThemeAsync.fulfilled, (state, action) => {
            state.status = APIStatus.IDLE;
        })
            .addCase(enableDisableThemeAsync.rejected, (state, action) => {
            state.status = APIStatus.FAILED;
            state.apiError = action.payload;
        });
        builder
            .addCase(enableDisableTeacherThemeAsync.pending, (state) => {
            state.status = APIStatus.PENDING;
            state.apiError = undefined;
        })
            .addCase(enableDisableTeacherThemeAsync.fulfilled, (state, action) => {
            state.status = APIStatus.IDLE;
        })
            .addCase(enableDisableTeacherThemeAsync.rejected, (state, action) => {
            state.status = APIStatus.FAILED;
            state.apiError = action.payload;
        });
    }
});
export const { setFeaturedImage } = themeSlice.actions;
export default themeSlice.reducer;
