import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Icon } from "@iconify/react";
import { Box, Typography, useTheme } from "@mui/material";
const GoalNotFound = () => {
    const theme = useTheme();
    return (_jsx(_Fragment, { children: _jsxs(Box, { sx: {
                width: "100%",
                m: "24px auto 0",
            }, children: [_jsxs(Box, { sx: { display: "flex", alignItems: "center", gap: 1 }, mb: 2, children: [_jsx(Icon, { icon: "ph:info-duotone", color: theme.palette.primary.main, fontSize: 56 }), _jsx(Typography, { variant: "h3", children: "Het geselecteerde doel is uitgeschakeld" })] }), _jsx(Typography, { variant: "h6", children: "Het geselecteerde doel is uitgeschakeld door de beheerder. De grafiek zal pas zichtbaar zijn als deze is ingeschakeld. Selecteer een ander doel om de grafiek te bekijken." })] }) }));
};
export default GoalNotFound;
