import { APIStatus } from '@application/common/enums/api-status';
import { createSlice } from '@reduxjs/toolkit';
import { getSeasonDropdownAsync, getSeasonListAsync } from './seasonThunk';
const initialState = {
    status: APIStatus.IDLE,
    seasonDropDownList: [],
    seasonList: [],
    activeSeason: 'primary'
};
export const seasonSlice = createSlice({
    name: 'season',
    initialState,
    reducers: {
        setActiveSeason(state, action) {
            state.activeSeason = action.payload;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(getSeasonDropdownAsync.pending, (state) => {
            state.status = APIStatus.PENDING;
            state.apiError = undefined;
        })
            .addCase(getSeasonDropdownAsync.fulfilled, (state, action) => {
            state.status = APIStatus.IDLE;
            state.seasonDropDownList = action.payload;
        })
            .addCase(getSeasonDropdownAsync.rejected, (state, action) => {
            state.status = APIStatus.FAILED;
            state.apiError = action.payload;
        });
        builder
            .addCase(getSeasonListAsync.pending, (state) => {
            state.status = APIStatus.PENDING;
            state.apiError = undefined;
        })
            .addCase(getSeasonListAsync.fulfilled, (state, action) => {
            state.status = APIStatus.IDLE;
            state.seasonList = action.payload;
        })
            .addCase(getSeasonListAsync.rejected, (state, action) => {
            state.status = APIStatus.FAILED;
            state.apiError = action.payload;
        });
    }
});
export const { setActiveSeason } = seasonSlice.actions;
export default seasonSlice.reducer;
