// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("data:image/svg+xml,%3Csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 24 24%27 width=%2724%27 height=%2724%27 color=%27%23ffffff%27 fill=%27none%27%3E%3Cpath d=%27M17.5 17.5L22 22%27 stroke=%27currentColor%27 stroke-width=%271.5%27 stroke-linecap=%27round%27 stroke-linejoin=%27round%27 /%3E%3Cpath d=%27M20 11C20 6.02944 15.9706 2 11 2C6.02944 2 2 6.02944 2 11C2 15.9706 6.02944 20 11 20C15.9706 20 20 15.9706 20 11Z%27 stroke=%27currentColor%27 stroke-width=%271.5%27 stroke-linejoin=%27round%27 /%3E%3Cpath d=%27M7.5 11L14.5 11M11 7.5V14.5%27 stroke=%27currentColor%27 stroke-width=%271.5%27 stroke-linecap=%27round%27 stroke-linejoin=%27round%27 /%3E%3C/svg%3E", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.kids-news-hover{cursor:pointer;transition:all .2s ease-in-out;border-radius:16px}.kids-news-hover:hover::before{position:absolute;top:0;left:0;width:100%;height:100%;content:url(${___CSS_LOADER_URL_REPLACEMENT_0___});color:#fff;text-align:center;display:flex;align-items:center;justify-content:center;background-color:rgba(0,0,0,.5)}`, "",{"version":3,"sources":["webpack://./src/ui/containers/teacher/news/style.scss"],"names":[],"mappings":"AAAA,iBACI,cAAA,CACA,8BAAA,CACA,kBAAA,CAEI,+BACI,iBAAA,CACA,KAAA,CACA,MAAA,CACA,UAAA,CACA,WAAA,CACA,+CAAA,CACA,UAAA,CACA,iBAAA,CACA,YAAA,CACA,kBAAA,CACA,sBAAA,CACA,+BAAA","sourcesContent":[".kids-news-hover {\r\n    cursor: pointer;\r\n    transition: all .2s ease-in-out;\r\n    border-radius: 16px;\r\n    &:hover {\r\n        &::before {\r\n            position: absolute;\r\n            top: 0;\r\n            left: 0;\r\n            width: 100%;\r\n            height: 100%;\r\n            content: url(\"data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' width='24' height='24' color='%23ffffff' fill='none'%3E%3Cpath d='M17.5 17.5L22 22' stroke='currentColor' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round' /%3E%3Cpath d='M20 11C20 6.02944 15.9706 2 11 2C6.02944 2 2 6.02944 2 11C2 15.9706 6.02944 20 11 20C15.9706 20 20 15.9706 20 11Z' stroke='currentColor' stroke-width='1.5' stroke-linejoin='round' /%3E%3Cpath d='M7.5 11L14.5 11M11 7.5V14.5' stroke='currentColor' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round' /%3E%3C/svg%3E\");\r\n            color: #fff;\r\n            text-align: center;\r\n            display: flex;\r\n            align-items: center;\r\n            justify-content: center;\r\n            background-color: rgba($color: #000000, $alpha: 0.5);\r\n        }\r\n    }\r\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
