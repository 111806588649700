import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import { Button } from "@mui/material";
const CoordinatorLogin = () => {
    const redirectToAdmin = () => {
        window.open('https://cms.wijzneus.nl', '_blank');
    };
    return (_jsx(_Fragment, { children: _jsx("div", { className: "form-container", children: _jsx(Button, { className: "btn", variant: "contained", type: "submit", onClick: redirectToAdmin, sx: {
                    padding: { lg: "18px 36px" },
                    color: "#00558A",
                    border: "3px solid #00558A",
                    backgroundColor: "#64CAFF",
                    lineHeight: "1.25",
                    minWidth: "165px",
                }, children: "Klik hier om in te loggen als Co\u00F6rdinator / IB" }) }) }));
};
export default CoordinatorLogin;
