import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useEffect } from "react";
import "./style.scss";
import { Accordion, AccordionDetails, AccordionSummary, Grid, Typography, useTheme, } from "@mui/material";
import { Icon } from "@iconify/react";
import { useAppDispatch, useAppSelector } from "@application/store/useStore";
import { getTeacherFaqsAsync } from "@application/features/teacher/teacherThunk";
const Faqs = () => {
    const dispatch = useAppDispatch();
    const theme = useTheme();
    const { teacherFaqs } = useAppSelector((state) => state.teacher);
    useEffect(() => {
        dispatch(getTeacherFaqsAsync());
    }, [dispatch]);
    return (_jsx(_Fragment, { children: _jsxs(Grid, { container: true, spacing: 4, maxWidth: "md", sx: { backgroundColor: "#fff", height: "100%", marginTop: "0" }, children: [_jsx(Grid, { item: true, xs: 2 }), _jsx(Grid, { item: true, xs: 10, children: _jsx(Grid, { container: true, maxWidth: "lg", spacing: 4, children: _jsx(Grid, { item: true, xs: 12, children: _jsxs("div", { children: [_jsx(Typography, { sx: {
                                            fontFamily: "Marykate",
                                            fontSize: 36,
                                            color: theme.palette.primary.main,
                                            mb: 2,
                                        }, children: "Veel Gestelde Vragen" }), teacherFaqs &&
                                        teacherFaqs.faqItems?.map((faqItem) => (_jsxs(Accordion, { sx: { borderRadius: "0 !important" }, children: [_jsx(AccordionSummary, { expandIcon: _jsx(Icon, { icon: "solar:alt-arrow-down-outline" }), "aria-controls": "panel1a-content", id: "panel1a-header", children: _jsx(Typography, { sx: {
                                                            fontFamily: "Marykate",
                                                            fontSize: 20,
                                                            color: "your-theme-palette-primary-main",
                                                        }, children: faqItem.question }) }), _jsx(AccordionDetails, { children: _jsx(Typography, { children: faqItem.answer }) })] }, faqItem.id)))] }) }) }) })] }) }));
};
export default Faqs;
