import { wijzneusApi } from './wijzneus-api';
const getCountryDropdown = () => {
    return wijzneusApi.get(`countries/dropdown`);
};
const addCountry = (request) => {
    return wijzneusApi.post(`countries`, request);
};
const CountryApi = {
    getCountryDropdown,
    addCountry
};
export default CountryApi;
