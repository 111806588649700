import { Fragment as _Fragment, jsx as _jsx } from "react/jsx-runtime";
import { getSeasonListAsync } from "@application/features/season/seasonThunk";
import { useAppDispatch, useAppSelector } from "@application/store/useStore";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
const TeacherMain = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const { currentUser } = useAppSelector((state) => state.auth);
    useEffect(() => {
        if (currentUser) {
            if (["Teacher", "Supervisor"].includes(currentUser.role)) {
                const teacherId = currentUser?.id;
                dispatch(getSeasonListAsync(teacherId)).then((result) => {
                    const seasonList = result.payload;
                    if (seasonList && seasonList.length > 0) {
                        const season = seasonList.find((x) => x.name.toLocaleLowerCase() === "autumn");
                        if (season) {
                            navigate(`/teacher/courses/${season.id}/${season.themeId}`);
                        }
                    }
                });
            }
        }
    }, [currentUser, dispatch, navigate]);
    return (_jsx(_Fragment, {}));
};
export default TeacherMain;
