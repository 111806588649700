import { getErrorPayload } from "../../common/helpers/api-helper";
import { createAsyncThunk } from "@reduxjs/toolkit";
import CountryApi from "@infrastructure/country-api";
export const getCountryDropDownAsync = createAsyncThunk("board/getCountryDropDown", async (_, { rejectWithValue, fulfillWithValue }) => {
    try {
        const response = await CountryApi.getCountryDropdown();
        return fulfillWithValue(response.data);
    }
    catch (error) {
        return rejectWithValue(getErrorPayload(error));
    }
});
