import { wijzneusApi } from './wijzneus-api';
import { convertModelToFormData } from './helpers';
const getThemesBySeasonandTeacher = (getThemeDto) => {
    return wijzneusApi.get(`teacher-course/${getThemeDto.seasonId}/${getThemeDto.teacherId}/themes`);
};
const enableDisableTeacherTheme = (enableDisableTheme) => {
    return wijzneusApi.patch(`teacher-theme`, enableDisableTheme);
};
const getThemes = () => {
    return wijzneusApi.get(`themes`);
};
const getTheme = (request) => {
    return wijzneusApi.get(`themes/${request}`);
};
const addTheme = (request) => {
    const formData = convertModelToFormData(request);
    return wijzneusApi.postForm(`themes`, formData);
};
const updateTheme = (request) => {
    const formData = convertModelToFormData(request);
    return wijzneusApi.putForm(`themes`, formData);
};
const enableDisableTheme = (request) => {
    return wijzneusApi.patch(`themes`, request);
};
const ThemeApi = {
    getThemes,
    getTheme,
    addTheme,
    updateTheme,
    enableDisableTheme,
    enableDisableTeacherTheme,
    getThemesBySeasonandTeacher
};
export default ThemeApi;
