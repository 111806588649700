import { wijzneusApi } from './wijzneus-api';
const getSubGoals = () => {
    //const filter = `&filter=${request.filter ? request.filter : ''}`;
    return wijzneusApi.get(`subgoals`);
};
const getSubGoal = (request) => {
    return wijzneusApi.get(`subgoals/${request}`);
};
const addSubGoal = (request) => {
    return wijzneusApi.post(`subgoals`, request);
};
const updateSubGoal = (request) => {
    return wijzneusApi.put(`subgoals`, request);
};
const enableDisableSubGoal = (request) => {
    return wijzneusApi.patch(`subgoals`, request);
};
const GoalApi = {
    getSubGoals,
    getSubGoal,
    addSubGoal,
    updateSubGoal,
    enableDisableSubGoal
};
export default GoalApi;
