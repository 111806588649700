import { wijzneusApi } from "./wijzneus-api";
const getCourses = () => {
    return wijzneusApi.get(`courses`);
};
const getCourseItems = (request) => {
    return wijzneusApi.get(`courses/${request}`);
};
const getThemeCourses = () => {
    return wijzneusApi.get(`kids/theme-course`);
};
const getCourse = (request) => {
    return wijzneusApi.get(`courses/${request}`);
};
const addCourse = (request) => {
    return wijzneusApi.post(`courses`, request);
};
const updateCourse = (request) => {
    return wijzneusApi.put(`courses`, request);
};
const enableDisableCourse = (request) => {
    return wijzneusApi.patch(`courses`, request);
};
const downloadAndViewCourse = (fileName) => {
    return wijzneusApi.get(`courses/${fileName}/download`);
};
const CourseApi = {
    getCourses,
    getCourseItems,
    getThemeCourses,
    getCourse,
    addCourse,
    updateCourse,
    enableDisableCourse,
    downloadAndViewCourse,
};
export default CourseApi;
