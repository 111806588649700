import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import translationEN from "./translations/en.json";
import translateNL from "./translations/nl.json";
import LngDetector from "i18next-browser-languagedetector";
import XHR from "i18next-http-backend";
const resources = {
    en: {
        translation: translationEN,
    },
    nl: {
        translation: translateNL,
    },
};
i18n
    .use(XHR)
    .use(LngDetector)
    .use(initReactI18next)
    .init({
    resources,
    lng: "nl",
    fallbackLng: "nl",
    keySeparator: ".",
    interpolation: {
        escapeValue: false,
    },
});
export default i18n;
