import { APIStatus } from '@application/common/enums/api-status';
import { createSlice } from '@reduxjs/toolkit';
import { getAnnualPlanAsync } from './annualPlanThunk';
const initialState = {
    status: APIStatus.IDLE,
    annualPlans: []
};
export const annualPlanSlice = createSlice({
    name: 'annualPlan',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getAnnualPlanAsync.pending, (state) => {
            state.status = APIStatus.PENDING;
            state.apiError = undefined;
        })
            .addCase(getAnnualPlanAsync.fulfilled, (state, action) => {
            state.status = APIStatus.IDLE;
            state.annualPlans = action.payload;
        })
            .addCase(getAnnualPlanAsync.rejected, (state, action) => {
            state.status = APIStatus.FAILED;
            state.apiError = action.payload;
        });
    }
});
export const {} = annualPlanSlice.actions;
export default annualPlanSlice.reducer;
