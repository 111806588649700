import { wijzneusApi } from './wijzneus-api';
const getSchools = () => {
    return wijzneusApi.get(`schools`);
};
const getSchool = (request) => {
    return wijzneusApi.get(`schools/${request}`);
};
const getSchoolDropdown = (boardId) => {
    return wijzneusApi.get(`schools/${boardId}/dropdown`);
};
const addSchool = (request) => {
    return wijzneusApi.post(`schools`, request);
};
const updateSchool = (request) => {
    return wijzneusApi.put(`schools`, request);
};
const enableDisableSchool = (request) => {
    return wijzneusApi.patch(`schools`, request);
};
const SchoolApi = {
    getSchools,
    getSchool,
    addSchool,
    updateSchool,
    enableDisableSchool,
    getSchoolDropdown
};
export default SchoolApi;
